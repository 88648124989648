.map-previews {
    display: flex;
    flex-wrap: wrap;

    .map-preview {
        border-radius: $border-radius;
        box-shadow: $box-shadow;
        color: $fg-color;
        flex-grow: 1;
        min-height: 8rem;
        margin: .5rem;
        min-width: 18rem;
        position: relative;

        a {
            color: $fg-color;
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }

        }

        .img {
            bottom: 0;
            left: 0;
            position: absolute;
            right: 0;
            top: 0;

            img {
                border-radius: $border-radius;
                height: 100%;
                object-fit: cover;
                width: 100%;
            }

        }

        .overlay {
            background-color: $bg-color;
            bottom: 0;
            left: 0;
            opacity: .8;
            position: absolute;
            right: 0;
            top: 0;
            transition: all .5s ease-in-out;
        }

        .info {
            line-height: 1;
            margin: 3rem 1rem 1rem auto;
            max-width: 20rem;
            position: relative;
            text-align: right;
            transition: all .5s ease-in-out;

            h3 {
                line-height: 1;
                margin-bottom: 0;
            }

        }

        &:hover {

            .overlay {
                opacity: .9;
            }

        }

    }

}