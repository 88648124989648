.page-footer {
    color: $fg-color-muted;
    height: 2rem;
    padding-right: 1rem;
    text-align: right;

    a {
        color: $fg-color-muted;
        text-decoration: none;
    }

}