.main-nav-toggle {
    display: none;
}

.main-nav {

    ul {
        list-style: none;
        margin: 0;
        padding: 0;

        li {
            display: inline-block;
            margin: 0;
            padding: 0;

            &:not( :last-child ) {
                margin-right: 1rem;
            }

        }

    }

}

@media only screen and ( max-width: 767px ) {

    .main-nav-toggle {
        display: block;
    }

    .main-nav {
        background-color: $bg-color;
        background-image: $bg-img;
        bottom: 0;
        left: 0;
        position: fixed;
        right: 0;
        top: $header-height;

        &:not( .open ) {
            display: none;
        }

        ul {
            margin: 0 auto;
            padding-right: calc( $grid-gutter-width * 0.5 );
            padding-left: calc( $grid-gutter-width * 0.5 );
            width: 100%;

            li {
                display: block;
                margin: 0 !important;
                text-align: right;

                a {
                    display: block;
                    font-size: 1.5rem;
                    padding: .5rem 0;
                }

            }

        }

    }

}

@media only screen and ( min-width: 576px ) and ( max-width: 767px ) {

    .main-nav {

        ul {
            max-width: 540px;
        }

    }

}