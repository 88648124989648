.page-header {
    background-color: $bg-color;
    background-image: $bg-img;
    box-shadow: $box-shadow;
    height: $header-height;
    line-height: 1;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1;

    a {
        color: $fg-color;
        text-decoration: none;
    }

}