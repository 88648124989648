input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px $bg-color inset !important;
    -webkit-text-fill-color: $fg-color !important;
}

input {
    box-shadow: $box-shadow;
}

.input-addon {
    position: relative;

    input {
        padding-right: 2.5rem;
    }

    span {
        line-height: 1;
        position: absolute;
        right: 1rem;
        top: 50%;
        transform: translateY(-50%);
    }

}

.mega-option {
    position: relative;

    .selector {
        background: $bg-color;
        border: 1px solid $border-color;
        border-radius: $border-radius;
        height: 50%;
        left: 0;
        opacity: .5;
        position: absolute;
        right: 0;
        top: 0;
        transition: all .5s ease-in-out;

        &.selector-0 {
            opacity: 0;
        }

        &.selector-1 {
            top: 0;
        }

        &.selector-2 {
            top: 50%;
        }

    }

    .option {
        cursor: pointer;
        padding: 1rem;
        position: relative;

        .price {
            color: $primary-color;
            float: right;
        }

    }

}