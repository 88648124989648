$fg-color:             #f4f4f4;
$fg-color-disabled:    #d4d4d4;
$fg-color-muted:       #6d6d6a;

$bg-color:             #1d1d2a;
$bg-color-disabled:    #0d0d1a;
$bg-gradient-color-1:  #1d1d2a;
$bg-gradient-color-2:  #333340;
$bg-img:                 linear-gradient(90deg, $bg-gradient-color-1, $bg-gradient-color-2 );

$border-color:          #444450;
$border-color-active:   #555560;

$primary-color:         #bc4d00;

$header-height:          4rem;